import {required} from 'vuelidate/lib/validators'
import { mapGetters, mapMutations, mapActions} from 'vuex';

export default {
    props: ['popupType', 'selectedItem'],
    data() {
        return {
            form: {
                user_id: 0,
                name: '',
                description: '',
                user_group_id: 0,
                login: '',
                password: '',
                new_password: '',
                email: '',
                created: '',
                departments:[]
            },
            changePassword: false,
        }
    },

    validations: {
        form: {
            name: {
                required
            },
        }
    },
    computed: {
        ...mapGetters({
            user:'contacts/user',
            usersGroup:'contacts/usersGroup',
            departments: 'catalog/departments',
        }),
    },
    watch:{
        user(e){
            if(e){
                this.getUserInfoData();
            }
        },
    },
    created() {
        if(this.user){
            this.getUserInfoData();
        }
        this.getUsersGroup();
        this.getDepartments();
    },
    methods: {
        getUserInfoData(){
            this.form = {
                user_id: this.user.id,
                name: this.user.name,
                user_group_id: this.user.user_group_id,
                login: this.user.login,
                email: this.user.email,
                description: this.user.description,
                created: this.$moment(this.user.created_at).format('YYYY-MM-DD HH:mm:ss'),
                departments: this.user.departments
            }
        },
        closePopup() {
            this.$emit('closePopup')
        },
        sendPriceCategory() {
            if(this.user){
                if(this.form.departments.length && !Number.isInteger(this.form.departments[0])){
                    const departments = [];
                    this.form.departments.map(item=>{departments.push(item.id)});
                    this.form.departments = departments;
                }
                this.$emit('changeUserForm', this.form)
            }else{
                this.$emit('sendUser', this.form)
            }
        },
        ...mapActions({
            getUsersGroup:'contacts/getUsersGroup',
            getDepartments: 'catalog/getDepartments',
        }),
        ...mapMutations({
            changeUser:'contacts/changeUser'
        }),
    },
    destroyed() {
        this.changeUser(null);
    }
}